<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar :title="list.comboName" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right></template>
    </van-nav-bar>
    <div style="padding-top:46px;background: #fff;">
      <img class="taocan_jindu" src="../../assets/img/taocan_jindu.png" alt />
      <van-row class="jindu_name">
        <van-col span="8" style="padding-left: 12px">购买套餐卡</van-col>
        <van-col span="8" style="text-align: center;">套餐卡下单</van-col>
        <van-col span="8" style="text-align: right;padding-right: 12px">到店服务</van-col>
      </van-row>
      <div class="taocan_benjin">
        <div class="taocan_name">{{list.comboName}}</div>
        <div class="taocan_name_b">适用所有车型，购买后{{list.validity}}月内有效</div>
        <div class="taocan_jia">
          {{list.price}}
          <span style="font-size: 16px;">元</span>
        </div>
        <div class="taocan_shen">立省{{list.sellPrice-list.price}}元</div>
      </div>
      <div class="chaozhi">
        <img
          style="vertical-align: middle;margin: 0px 2px 0px 6px;"
          class="tao_gou"
          src="../../assets/img/taocan_gou.png"
          alt
        />
        <span style="vertical-align: middle;">超值</span>
      </div>
      <div class="tanan_xq">套餐卡详情</div>
      <div class="list">
        <div class="taocan_list" v-for="(item,idx) in list.comboItems" :key="idx">
          <div class="left">
            <img class="tao_che" src="../../assets/img/taocan_che.png" alt />
          </div>
          <div class="right" style=" width: 90%;">
            <div class="list_a">
              <span class="left">{{item.itemName}}</span>
              <span class="right">{{item.totalTimes}}次</span>
            </div>
            <div class="list_b">
              <span class>
                单次
                <span style="color: #d9314c; font-weight: bold;">{{item.newPrice}}</span>元，比原价省
                <span
                  style="color: #d9314c; font-weight: bold;"
                >{{item.oldPrice - item.newPrice}}</span>元
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="tanan_xq">购买须知</div>
      <div class="shuoming">
        <p>1.本套餐卡仅支持 {{shangjia.name}} 使用，其它类型门店概不支持；</p>
        <p>2.本套餐自购买之日起{{list.validity}}月内有效；</p>
        <p>3.本套餐不与 {{shangjia.name}} 线上其他同期优惠活动叠加使用</p>
        <p>4.本套餐包含优惠服务，不提供优先、插队等特权，请用户提前与门店沟通安排行程，如需排队请耐心等候；</p>
        <p v-if="list.isLimitCarNo == 0">5.此套餐卡不绑定车牌，可以和朋友车辆共享服务；</p>
        <p v-if="list.isLimitCarNo == 1">5.此套餐卡绑定车牌，限制当前车牌号使用；</p>
        <p>6.本活动最终解释权归驿车驹；</p>
      </div>
    </div>
    <div style="height: 54px;"></div>
    <div class="bott_tao">
      <div class="left bott_a">
        <!-- <img class="kefu" src="../../assets/img/kefu.png" alt /> -->
        <a :href="'tel:'+shangjia.tel1">
          <van-icon name="phone-o" />
          <p class="zaixian">在线客服</p>
        </a>
      </div>
      <div class="bott_ta right" @click="goumai">立即抢购</div>
      <div class="bott_b right">
        <div class="zj">
          <span class="danwei">特价：</span>
          <span class="monede">
            <span class="jin_biao">￥</span>
            {{list.price}}
          </span>
        </div>
        <div class="yj">
          <span class="danwei">原价总计：</span>
          <del class="zhe">￥{{list.sellPrice}}</del>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      huiyuan: JSON.parse(sessionStorage.getItem("huiyuan")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: {},
      type: this.$route.query.type,
      page: 0,
      size: 10,
      zhehou: 100,
      zongjia: 120,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    goumai() {
      var talis = {
        activityPrice: this.list.price,
        // activityUrl: this.list.activityUrl,
        id: this.list.id,
        name: this.list.comboName,

        type: 4
      };
      sessionStorage.setItem("huiyuan", JSON.stringify(talis));
      this.$router.push({
        path: "ka_pay",
        query: {
          isok: true,
          shareId: this.$route.query.cid
        }
      });
    },
    onLoads() {},
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      if (this.$route.query.cid != undefined) {
        this.$router.push({
          path: "user"
        });
      } else {
        this.$router.go(-1);
      }
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("Agid")
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "onMenuShareAppMessage"
              ]
            });
             if (this.$route.query.cid != undefined) {
               var link ="https://shopping.car-posthouse.cn/member_details?gid=" +
                  sessionStorage.getItem("Agid") +
                  "&cid=" +this.$route.query.cid +
                  "&appid=" +
                  sessionStorage.getItem("appid") +
                  "&id=" +
                  this.list.id +
                  "&denglu=" +
                  true
             }else{
                   var link ="https://shopping.car-posthouse.cn/member_details?gid=" +
                  sessionStorage.getItem("Agid") +
                  "&cid=" +
                  this.userInfo.id +
                  "&appid=" +
                  sessionStorage.getItem("appid") +
                  "&id=" +
                  this.list.id +
                  "&denglu=" +
                  true
             }
             
            wx.ready(() => {
              wx.onMenuShareAppMessage({
                title: this.shangjia.name,
                desc: this.list.urlName,
                link:link,
                imgUrl:this.list.urlImg,
                success: function() {}
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    }
  },
  created() {
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&id=" +
        this.$route.query.id +
        "&cid=" +
        this.$route.query.cid;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      sessionStorage.setItem("cid", this.$route.query.cid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;


        })
        .catch(() => loading.clear());
    }

    // var huiyuan = JSON.parse(sessionStorage.getItem("huiyuan"));
    let data = {
      id: this.$route.query.id
    };
    const loading = this.$toast.loading({
      mask: true,
      duration: 0,
      message: "加载中..."
    });

    orderModel
      .tancandetails(data)
      .then(e => {
        loading.clear();
        this.list = e.data;
          this.getConfig();
        console.log(this.list);
      })
      .catch(() => loading.clear());
  
    var isok = localStorage.getItem("isok");
    if (isok == "true") {
      setTimeout(res => {
        this.$router.go(0);
      }, 100);
      localStorage.removeItem("isok");
    }
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.huiyuan_img {
  width: 100%;
}

// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #131818;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_c {
  overflow: hidden;
  margin-top: 10px;
  font-size: 14px;
  background: #e4d2a8;
  color: #000;
  //   font-weight: bold;
  padding: 7px 50px;
  border-radius: 19px;
}
.huodjia {
  color: red;
  font-size: 16px;
}
.zhenjia {
  font-size: 12px;
  color: #666;
  margin-left: 3px;
}
.kaitong {
  font-weight: bold;
  margin-left: 50px;
}
.taocan_jindu {
  height: 40px;
  width: 90%;
  margin: auto;
  display: block;
}
.jindu_name {
  font-size: 12px;
  line-height: 16px;
}
.taocan_benjin {
  background-image: url(../../assets/img/taocan_img.png);
    background-size: 100% 100%;
  width: 96%;
  height: 190px;
  margin: auto;
  margin-top: 20px;

  font-size: 12px;
  color: #fff;
  position: relative;
}
.taocan_name {
  font-size: 16px;
  margin-left: 27px;
  padding-top: 15px;
}
.taocan_name_b {
  margin-left: 20px;
  line-height: 18px;
}
.taocan_jia {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 45px;
  margin-bottom: 8px;
  margin-left: 20px;
}
.taocan_shen {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  margin-bottom: 8px;
  margin-right: 15px;
}
.chaozhi {
  font-size: 12px;
  color: #666;
  margin-top: 7px;
  padding-bottom: 8px;
  border-bottom: 8px solid #f5f5f5;
}
.tanan_xq {
  line-height: 34px;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 12px;
}
.tao_gou {
  width: 17px;
}
.taocan_list {
  overflow: hidden;
  padding: 12px 15px 0px 12px;
}
.list_a {
  font-size: 14px;
  overflow: hidden;
}
.list {
  border-bottom: 8px solid #f5f5f5;
}
.list_b {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
  border-bottom: 1px dotted #f5f5f5;
  padding-bottom: 6px;
}
.tao_che {
  width: 22px;
  margin-top: 1px;
}
.shuoming {
  font-size: 12px;
  padding: 12px 12px 0 12px;
  color: #666;
}
.shuoming p {
  line-height: 20px;
}
// 底部
.bott_tao {
  height: 50px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_ta {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  padding: 0px 25px;
  border-radius: 19px;
}
.kefu {
  width: 25px;
  height: 25px;
}
.bott_a {
  text-align: center;
  margin-top: 5px;
}
.bott_b {
  font-size: 13px;
  margin-right: 10px;
  margin-top: 5px;
}
.zaixian {
  font-size: 12px;
  line-height: 12px;
}
.danwei {
  font-size: 12px;
  color: #666;
}
.van-icon {
  font-size: 20px;
}
.jin_biao {
  font-size: 14px;
}
.monede {
  font-size: 22px;
  font-weight: bold;
  color: #d9314c;
}
.zhe {
  color: #666;
  margin-left: 5px;
}
.zj {
  line-height: 20px;
}
.yj {
  line-height: 20px;
}
//
</style>